<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="user">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                Manage {{ user.first_name }} {{ user.last_name }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a onclick="history.back(); return false;" class="button">Back to Manage Users</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal
                    has-modal-card
                    v-model="viewRegistrantsSwitch">
                    <template #default="props">
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">View Registrants for {{ displayRegistrants.name }} </p>
                                <button
                                    type="button"
                                    class="delete"
                                    @click="props.close"/>
                            </header>
                            <section class="modal-card-body">
                                <b-tabs>
                                    <b-tab-item label="View Registrants">
                                        <table style="width: 100%" class="table">
                                            <tr :key="registrant.id" v-for="(registrant, key) in displayRegistrants.registrants">
                                                <td style="width: 50%;">{{ registrant.user.first_name }} {{ registrant.user.last_name }}</td>
                                                <td>
                                                    <span v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true">On Waitlist</span>
                                                </td>
                                                <td>
                                                    <a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 0 || registrant.on_waitlist === false" class="button is-small is-info">Move Onto Waitlist</a>
                                                    <a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true" class="button is-small is-warning">Move Off Waitlist</a>
                                                </td>
                                                <td>
                                                    <a v-on:click="removeRegistrant(registrant, key)" class="button is-small is-danger">Remove from Registrants</a>
                                                </td>
                                            </tr>
                                        </table>
                                    </b-tab-item>
                                    <b-tab-item label="Add to Registrants">
                                        <div class="add-a-registrant">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-field label="Add a Registrant">
                                                        <b-input v-on:input="updatePlayerSearch($event)" placeholder="Add their SPN registered email"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin: 20px;">
                                            <nav class="level" v-for="(user, key) in searchList" :key="key">
                                                <div class="level-left">
                                                    <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                                                    <p class="level-item">{{ user.city }}</p>
                                                    <p class="level-item">{{ user.gender }}</p>
                                
                                                    <p class="level-item">
                                                        <RankComponent :currentRank="user.current_rank_id"
                                                        :previousRank="user.previous_rank_id"></RankComponent>
                                                        
                                                    </p>
                                                    <p class="level-item">
                                                        <span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
                                                    </p>
                                                </div>
                                                <div class="level-right">
                                                    <a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="adding">
                                            <p><b>Adding:</b></p>
                                        </div>
                                        <section class="modal-card-body">
                                            <nav class="level" v-for="(user, key) in playersSelected" :key="key">
                                                <div class="level-left">
                                                    <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                                                    <p class="level-item">{{ user.city }}</p>
                                                </div>
                                                <div class="level-right">
                                                    <a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </nav>
                                        </section>
                                        <footer class="modal-card-foot">
                                            <a class="admin-button" role="button" v-on:click="addToClinic()">Add Players To Clinic</a>
                                        </footer>
                                    </b-tab-item>
                                </b-tabs>
                            </section>
                        </div>
                    </template>


                    
                </b-modal>
                <b-tabs style="margin-top: 30px;">
                    <b-tab-item label="Profile">
                       <div class="row">
                           <div class="col-md-2">Quick Tools: </div>
                           <div class="col-md-2">
                               <a class="button" v-on:click="resetPassword(user)">Reset Password</a>
                           </div>
                           <div class="col-md-2">
                               <span v-if="user.confirmed === null">
                                    <a class="button" v-on:click="resendConfirmAccount(user)">Resend Confirm Account</a>
                                </span>
                           </div>
                           <div class="col-md-2">
                                <span v-if="user.failed_login_attempts > 0">
                                    <a class="button" v-on:click="resetFailedAttempts(user)">Reset Failed Login Attempts ({{ user.failed_login_attempts }})</a>
                                </span>
                           </div>
                           <div class="col-md-2">
                               <span>
                                    <a class="button" v-on:click="sendWaiverReminder(user)">Resend Waiver</a>
                                </span>
                           </div>
                       </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Basic Details
                                            <a class="button is-small" style="float: right;" v-if="basicDetailsEditSwitch === false"
                                            v-on:click="basicDetailsEditSwitch = !basicDetailsEditSwitch">
                                                Edit
                                            </a>
                                            <a v-on:click="saveBasicDetails()" class="button is-small is-info" style="float: right;" v-if="basicDetailsEditSwitch === true">
                                                Save
                                            </a>
                                             <a class="button is-small is-danger" style="float: right;" 
                                            v-on:click="basicDetailsEditSwitch = false" v-if="basicDetailsEditSwitch === true">
                                                Cancel
                                            </a>
                                        </div>
                                        <div class="panel-content">
                                            <table class="table"  style="width: 100%">
                                                <tbody>
                                                    <tr>
                                                        <td><b>ID</b></td>
                                                        <td>{{ user.id }}</td>
                                                    </tr>
                                                    <tr v-if="user.banned_until || basicDetailsEditSwitch === true">
                                                        <td style="color: red;"><b>Banned Until:</b></td>
                                                        <td style="color: red;">
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ moment(user.banned_until).utc().subtract(1, 'days').format('YYYY-MM-DD') }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-datepicker 

                                                                v-model="duplicateUser.banned_until">
                                                                </b-datepicker>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Region:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                <span v-if="user.region">
                                                                    {{ user.region.name }}   
                                                                </span> 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-select v-model="duplicateUser.default_region_id">
                                                                    <option :value="1">Alberta</option>
                                                                    <option :value="2">British Columbia</option>
                                                                    <option :value="3">Manitoba</option>
                                                                    <option :value="4">Ontario</option>
                                                                    <option :value="5">Maritimes</option>
                                                                    <option :value="6">Quebec</option>
                                                                    <option :value="7">Newfoundland Labrador</option>
                                                                    <option :value="8">Saskatchewan</option>
                                                                </b-select>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>First Name:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.first_name }}    
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.first_name"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Middle Name:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.middle_name }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.middle_name"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Last Name:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.last_name }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.last_name"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Email:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.email }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.email"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Phone:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.phone }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.phone"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Username:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.username }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-input v-model="duplicateUser.username"></b-input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>DOB:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ moment(user.date_of_birth).utc().format('YYYY-MM-DD') }}
                                                            </span>
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                
                                                                <b-datepicker 
                                                                    :years-range="[-100, +40]"
                                                                    v-model="duplicateUser.date_of_birth"
                                                                    >
                                                                </b-datepicker>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Gender:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.gender }} 
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-select v-model="duplicateUser.gender">
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Not Disclosed">Prefer Not To Disclose</option>
                                                                    <option value="Self Identify As">Self Identify As</option>
                                                                </b-select>
                                                                <b-field v-if="duplicateUser.gender === 'Self Identify As'" :label="(en === true ? 'Identified Gender*' : 'Le genre' )">
                                                                    <b-input v-model="registerInfo.identify_as"></b-input>
                                                                </b-field>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Current Rank:</b></td>
                                                        <td>
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                <RankComponent :currentRank="user.current_rank_id"
                                                                :previousRank="user.previous_rank_id"
                                                                ></RankComponent>
                                                                
                                                                <a v-on:click="confirmNewRank(user)" class="button button-small" v-if="user.previous_rank_id && user.previous_rank_id !== user.current_rank_id">Confirm New Rank</a>
                                                        
                                                                <p v-if="user.previous_rank_id && user.previous_rank_id !== user.current_rank_id">This will remove the *</p>
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-select v-model="duplicateUser.current_rank_id">
                                                                    <option value="A">A</option>
                                                                    <option value="B">B</option>
                                                                    <option value="C">C</option>
                                                                    <option value="D">D</option>
                                                                    <option value="E">E</option>
                                                                </b-select>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Positions:</b></td>
                                                        <td>
                                                            <b-tag v-for="position in JSON.parse(user.positions)" :key="position">
                                                                {{ position }}
                                                            </b-tag>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Stripe Customer ID:</b></td>
                                                        <td>{{ user.stripe_customer_id }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Reset Password Timestamp:</b></td>
                                                        <td><span v-if="user.reset_password_timestamp">{{ moment(user.reset_password_timestamp).format('YYYY-MM-DD h:mmA') }}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Reset Password Token</b></td>
                                                        <td style="font-size: 10px;">
                                                            {{ user.reset_password_token }}

                                                            <p style="font-size: 8px;">If they don't get the email, send them this link: </p>
                                                            <p style="font-size: 8px;">https://slo-pitch.com/reset-password/{{ user.reset_password_token }}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Updated Account</b></td>
                                                        <td>
                                                            
                                                            
                                                            <span v-if="basicDetailsEditSwitch === false">
                                                                {{ user.updated_account === 1 ? 'Yes' : 'No' }}
                                                                <p class="note">If no, Hasn't logged in yet</p>
                                                            </span>    
                                                            <span v-if="basicDetailsEditSwitch === true">
                                                                <b-select v-model="duplicateUser.updated_account">
                                                                    <option :value="1">Yes</option>
                                                                    <option :value="null">No</option>
                                                                </b-select>
                                                                <p class="note">Set this if you want them to re-fill out their personal info next time they log in.</p>
                                                            </span>
                                                            <!--  -->
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Has Logged In Before</b></td>
                                                        <td>
                                                            {{ user.has_logged_in_before === 1 ? 'Yes' : 'No' }} Latest: {{ moment(user.last_login).format('YYYY-MM-DD') }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Email Confirmed</b></td>
                                                        <td>
                                                            {{ user.confirmed === 1 ? 'Yes' : 'No' }}
                                                            <p class="note">If no, Needs to Confirm the Account to Log In</p>

                                                            <a v-if="user.confirmed !== 1" v-on:click="manuallyConfirmUser()" class="button">Manually Confirm User</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>
                                    
                                </div>  
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Waivers Signed <a v-on:click="resetWaiver()" class="button is-small">Reset Waiver</a>
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%">
                                                <tr :key="waiver.id" v-for="waiver in user.waivers">
                                                    <td>{{ moment(waiver.created_at).format('YYYY-MM-DD h:mmA') }}</td>
                                                    <td>{{ waiver.waiver_id }}</td>
                                                    <td><a class="button is-small" :href="`/superadmin/manage/users/${user.id}/waivers`">View Waiver</a></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="panel">
                                    <div class="panel-heading">
                                        IP Log
                                    </div>
                                    <div class="panel-content">
                                        <table class="table" style="width: 100%">
                                            <tr :key="ip.id" v-for="ip in user.ips">
                                                <td>{{ moment(ip.created_at).format('YYYY-MM-DD h:mmA') }}</td>
                                                <td>{{ ip.ip_address }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>  
                                </div> 
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Security
                                            <a class="button is-small" style="float: right;" v-if="securityEditSwitch === false"
                                                v-on:click="securityEditSwitch = !securityEditSwitch">
                                                    Edit
                                            </a>
                                            <a class="button is-small is-info" style="float: right;" 
                                            v-on:click="saveSecurityDetails()" v-if="securityEditSwitch === true">
                                                Save
                                            </a>
                                            <a class="button is-small is-danger" style="float: right;" 
                                            v-on:click="securityEditSwitch = false" v-if="securityEditSwitch === true">
                                                Cancel
                                            </a>
                                        </div>
                                        <div class="panel-content">
                                            <div class="padding">
                                                <table class="table" style="width: 100%">
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Question:</b></td>
                                                            <td>
                                                                <span v-if="securityEditSwitch === false">
                                                                    {{ user.security_question }} 
                                                                </span>    
                                                                <span v-if="securityEditSwitch === true">
                                                                    <b-input v-model="duplicateUser.security_question"></b-input>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Answer:</b></td>
                                                            <td>
                                                                <span v-if="securityEditSwitch === false">
                                                                    {{ user.security_answer }} 
                                                                </span>    
                                                                <span v-if="securityEditSwitch === true">
                                                                    <b-input v-model="duplicateUser.security_answer"></b-input>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Emergency
                                            <a class="button is-small" style="float: right;" v-if="emergencyEditSwitch === false"
                                                v-on:click="emergencyEditSwitch = !emergencyEditSwitch">
                                                    Edit
                                            </a>
                                            <a class="button is-small is-info" style="float: right;" 
                                            v-on:click="saveEmergencyDetails()"
                                            v-if="emergencyEditSwitch === true">
                                                Save
                                            </a>
                                            <a class="button is-small is-danger" style="float: right;" 
                                            v-on:click="emergencyEditSwitch = false" v-if="emergencyEditSwitch === true">
                                                Cancel
                                            </a>
                                        </div>
                                        <div class="panel-content">
                                            <div class="padding">
                                                <table class="table"  style="width: 100%">
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Name:</b></td>
                                                            <td>
                                                                <span v-if="emergencyEditSwitch === false">
                                                                    {{ user.emergency_contact }} 
                                                                </span>    
                                                                <span v-if="emergencyEditSwitch === true">
                                                                    <b-input v-model="duplicateUser.emergency_contact"></b-input>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Phone:</b></td>
                                                            <td>
                                                                <span v-if="emergencyEditSwitch === false">
                                                                    {{ user.emergency_contact_phone }} 
                                                                </span>    
                                                                <span v-if="emergencyEditSwitch === true">
                                                                    <b-input v-model="duplicateUser.emergency_contact_phone"></b-input>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Address
                                            <a class="button is-small" style="float: right;" v-if="addressEditSwitch === false"
                                                v-on:click="addressEditSwitch = !addressEditSwitch">
                                                    Edit
                                            </a>
                                        
                                            <a class="button is-small is-info" style="float: right;" 
                                            v-on:click="saveAddressDetails()" v-if="addressEditSwitch === true">
                                                Save
                                            </a>
                                            <a class="button is-small is-danger" style="float: right;" 
                                            v-on:click="addressEditSwitch = false" v-if="addressEditSwitch === true">
                                                Cancel
                                            </a>
                                        </div>
                                        <div class="panel-content">
                                            <div class="padding">
                                                <table class="table" v-if="addressEditSwitch === false" style="width: 100%">
                                                    <tbody>
                                                        <!-- <tr>
                                                            <td><b>Written Address (Non-Google):</b></td>
                                                            <td>
                                                                <span v-if="addressEditSwitch === false">
                                                                    {{ user.full_written_address }} 
                                                                </span>    
                                                            </td>
                                                        </tr> -->
                                                        <tr>
                                                            <td><b>Address:</b></td>
                                                            <td>
                                                                <span v-if="addressEditSwitch === false">
                                                                    {{ user.street_address }} 
                                                                </span>    
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>City:</b></td>
                                                            <td>
                                                                <span v-if="addressEditSwitch === false">
                                                                    {{ user.city }} 
                                                                </span>    
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Province:</b></td>
                                                            <td>
                                                                <span v-if="addressEditSwitch === false">
                                                                    {{ user.province }} 
                                                                </span>    
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Postal Code:</b></td>
                                                            <td>
                                                                <span v-if="addressEditSwitch === false">
                                                                    {{ user.postal_code }} 
                                                                </span>    
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <!-- <b-field v-if="addressEditSwitch" label="Full Written Address">
                                                    <b-input v-model="user.full_written_address"></b-input>
                                                </b-field> -->
                                                <b-field v-if="addressEditSwitch" label="Street Address">
                                                    <b-input v-model="user.street_address"></b-input>
                                                </b-field>
                                                <b-field v-if="addressEditSwitch" label="City">
                                                    <b-input v-model="user.city"></b-input>
                                                </b-field>
                                                <b-field v-if="addressEditSwitch" label="Province">
                                                    <b-input v-model="user.province"></b-input>
                                                </b-field>
                                                <b-field v-if="addressEditSwitch" label="Postal Code">
                                                    <b-input v-model="user.postal_code"></b-input>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                
                                    
                                
                                    <div class="panel">
                                        <div class="panel-heading">
                                            User Notes
                                        </div>
                                        <div class="panel-content">
                                            <div class="user-note padding" :key="key" v-for="(note, key) in notes.slice(0,sliceNum)">
                                                <b-tag v-if="note.exam_note === 1">Exam Change</b-tag>
                                                <div class="user-note-note" style="padding-bottom: 10px;">{{ note.notes }}</div>
                                                <div class="note">
                                                    {{ moment(note.created_at).format('YYYY-MM-DD h:mmA') }} by {{ note.written.first_name }} {{ note.written.last_name }}
                                                </div>
                                            </div>
                                            <div style="text-align: center;">
                                                <a class="button is-small" v-on:click="sliceNum = notes.length">View All</a>
                                            </div>
                                            <br>
                                            <b-field>
                                                <b-input type="textarea" v-model="note"></b-input>
                                            </b-field>
                                            
                                            <div style="text-align: center; margin-top: 10px; padding-bottom: 10px;">
                                                <a class="admin-button" role="button" v-on:click="addNote()">Add Note</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="authPermissions.merge_users === 1">
                                        <a class="button is-danger" v-on:click="deleteThisUser()" v-if="!user.deleted_at">Delete This User</a>
                                    </div>
                                    <a class="button is-danger" style="margin-bottom: 10px;" v-if="user.deleted_at" v-on:click="undelete()">Undelete This User</a>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Player/Coach">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Teams They're On
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;" v-if="teams_theyre_on">
                                                <tr :key="key" v-for="(team, key) in teams_theyre_on">
                                                    <td>
                                                        <b-tag type="is-success" v-if="team.division_id"><b>{{ team.division_id.toUpperCase() }}</b></b-tag>
                                                        <b-tag type="is-warning" v-if="team.ported">{{ team.ported ? 'Created on Old System' : '' }}</b-tag>
                                                    </td>
                                                    <td>
                                                        {{ team.name }}
                                                    </td>
                                                    <td>
                                                        {{ team.season }}
                                                    </td>
                                                    <td>
                                                        {{ team.city }}
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                        <span class="tag is-success" v-if="team.role_on_team === 1">
                                                            Coach
                                                        </span>
                                                        <span class="tag is-warning" v-if="team.role_on_team === 2">
                                                            Team Contact
                                                        </span>
                                                        <span class="tag is-danger" v-if="team.role_on_team === 3">
                                                            Player
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/teams/' + team.slug" v-if="team.deleted_at === null" class="button is-small">View Team</a>
                                                        <a :href="'/superadmin/manage/teams/' + team.slug" v-if="team.deleted_at !== null" class="button is-small">Deleted</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Tournaments They're In
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;"  v-if="tournaments_entered">
                                                <tr :key="key" v-for="(tournament, key) in tournaments_entered">
                                                    <td>
                                                        {{ tournament.tournament.name }}
                                                        <br><span style="font-size: 12px; font-weight: 700;">{{ tournament.team.name }}</span>
                                                    </td>
                                                    <td>
                                                        {{ tournament.tournament.season_id }}
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/tournaments/' + tournament.tournament.id" class="button 
                                                        is-small">View Tournament</a>
                                                        
                                                    </td>
                                                    <td>
                                                        <a :href="`/superadmin/manage/tournament/${tournament.tournament.slug}/event-roster/${tournament.registration.id}`" class="button is-small is-warning">View Event Roster</a>
                                                        
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/teams/' + tournament.team.slug" class="button is-small is-danger">View Team</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Leagues They're In
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;"  v-if="leagues_entered">
                                                <tr :key="key" v-for="(league, key) in leagues_entered">
                                                    <td>
                                                        {{ league.league.name }}
                                                        <br><span style="font-size: 12px; font-weight: 700;">{{ league.team.name }}</span>
                                                    </td>
                                                    <td>
                                                        {{ league.league.season_id}}
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/leagues/' + league.league.id" class="button is-small">View League</a>
                                                        
                                                    </td>
                                                    <td>
                                                        <a :href="`/superadmin/manage/leagues/${league.league.slug}/event-roster/${league.registration.id}`" class="button is-small is-warning">View Event Roster</a>
                                                        
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/teams/' + league.team.slug" class="button is-small is-danger">View Team</a>
                                                    </td>                  
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Convenor"  v-if="user.convenor_permissions">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Tournaments Hosted
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr :key="key" v-for="(tournament, key) in user.tournaments_running">
                                                    <td>
                                                        {{ tournament.name }}
                                                    </td>
                                                    <td>
                                                        <b-tag>{{ tournament.season_id }}</b-tag>
                                                    </td>
                                                    <td>
                            
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/tournaments/' + tournament.id" class="button is-small">View Tournament</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Leagues Hosted
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr :key="key" v-for="(league, key) in user.leagues_running">
                                                    <td>
                                                        {{ league.name }}
                                                    </td>
                                                    <td>
                                                        <b-tag>{{ league.season_id }}</b-tag>
                                                    </td>
                                                    <td>
        
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/leagues/' + league.id" class="button is-small">View League</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Posters Generated
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr :key="key" v-for="(league, key) in user.leagues_running">
                                                    <td>
                                                        {{ league.name }}
                                                    </td>
                                                    <td>
                  
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Umpire" v-if="user.umpire_permissions">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Current Certificate
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr>
                                                    <td><b>Cert #:</b></td>
                                                    <td>{{ user.umpire_permissions.cert_number }}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Valid Until:</b></td>
                                                    <td>{{ user.umpire_permissions.valid_until }}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Approved?</b></td>
                                                    <td>{{ user.umpire_permissions.approved === null ? 'No' : 'Yes' }}</td>
                                                </tr>
                                            </table>
                                            <a v-if="user.umpire_permissions.order_id" :href="`/superadmin/manage/orders?id=${user.umpire_permissions.order_id}`" class="button">View Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Clinics Hosted
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr :key="key" v-for="(clinic, key) in user.clinics_hosted">
                                                    <td>
                                                        {{ clinic.name }}
                                                    </td>
                                                    <td>
                              
                                                    </td>
                                                    <td>
                                                        <a v-on:click="displayRegistrants = clinic; viewRegistrantsSwitch = true" class="button is-small">View Clinic</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Clinics Attended
                                        </div>
                                        <div class="panel-content">
                                            <table class="table" style="width: 100%;">
                                                <tr :key="key" v-for="(clinic, key) in user.clinics_attended">
                                                    <td>
                                                        {{ clinic.clinic.name }}
                                                    </td>
                                                    <td>
                                  
                                                    </td>
                                                    <td>
                                                        <a :href="'/superadmin/manage/users/'+clinic.clinic.clinician_user_id" class="button is-small">View Clinician</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span v-if="user.umpire_permissions">
                            <div class="certificate" v-if="user.umpire_permissions.approved !== null">
                                <UmpCertificate :en="en" :userId="user.id"></UmpCertificate>
                            </div>
                        </span>
                        <!-- clinics hosted -->
                        <!-- umpire cheat sheets added to -->
                    </b-tab-item>
                    <b-tab-item label="Superadmin" v-if="user.user_permissions">
                        <!-- deleted bys (if superadmin) -->
                        <div class="row">
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Deleted By
                                        </div>
                                        <div class="panel-content">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Permissions">
                        <!-- permissions + permission tables -->     
                        
                        <div class="row" v-if="authUser.overwhelming_access">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Superadmin Permissions

                                            <a v-if="!user.user_permissions" v-on:click="addSuperadminPermissions()" style="float: right;" class="button is-small">Add Superadmin Permissions</a>
                                        </div>
                                        <div class="panel-content" v-if="user.user_permissions">
                                            <!-- <div class="padding" style="padding: 20px;"></div> -->
                                                <b-field multiline grouped>
                                                    <b-switch v-model="user.user_permissions.superadmin_access"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Superadmin Access
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_site_pages"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Site Pages
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_blog_posts"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Blog Posts
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_leagues"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Leagues
                                                    </b-switch>
                                                </b-field>
                                                <b-field multiline grouped>
                                                    <b-switch v-model="user.user_permissions.manage_tournaments"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Tournaments
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_teams"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Teams
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_umpires"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Umpires
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_clinics"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Clinics
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_players"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Players
                                                    </b-switch>
                                                </b-field>
                                                <b-field multiline grouped>
                                                    <b-switch v-model="user.user_permissions.manage_forms"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Forms
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_form_results"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Form Results
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.communication_log"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Communication Log
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.merge_users"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Merge Users
                                                    </b-switch>
                                                    
                                                </b-field>
                                                <b-field multiline grouped>
                                                    <b-switch v-model="user.user_permissions.manage_umpire_exams"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Umpire Exams
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_convenors"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Convenors
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_orders"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Orders
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_permissions"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Permissions
                                                    </b-switch>
                                                    <b-switch v-model="user.user_permissions.manage_region_rates"
                                                        :true-value="1"
                                                        v-on:input="updateSuperadmin()"
                                                        :false-value="null">
                                                        Manage Region Rates
                                                    </b-switch>
                                                </b-field>
                                        </div>
                                        <div class="panel-content" v-else>
                                            <div class="padding">
                                                No superadmin permissions available for this user.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Umpire Permissions

                                            <a v-if="!user.umpire_permissions" v-on:click="addUmpirePermissions()" style="float: right;" class="button is-small">Add Umpire Permissions</a>
                                        </div>
                                        <div class="panel-content" v-if="user.umpire_permissions">
                                            <div class="padding" style="padding: 20px;">
                                                <b-field multiline grouped>
                                                    <b-input style="width: 400px;" v-model="user.umpire_permissions.cert_number"></b-input>
                                                    <a v-on:click="updateCertNumber()" class="button">Update Cert Number</a>
                                                    <b-switch v-model="user.umpire_permissions.cheat_sheet"
                                                        :true-value="1"
                                                        v-on:input="updateUmpire()"
                                                        :false-value="null">
                                                        Umpire Cheat Sheet
                                                    </b-switch>
                                                    <b-switch v-model="user.umpire_permissions.clinics"
                                                        :true-value="1"
                                                        v-on:input="updateUmpire()"
                                                        :false-value="null">
                                                        Umpire Clinics
                                                    </b-switch>
                                                   
                                                </b-field>
                                                <a v-on:click="removeUmpirePermissions(user)" class="button">Remove Permissions</a>
                                            </div>
                                        </div>
                                        <div class="panel-content" v-else>
                                            <div class="padding">
                                                This player is not carded.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Convenor Permissions
                                            <a  v-if="!user.convenor_permissions"  v-on:click="addConvenorPermissions()" style="float: right;" class="button is-small">Add Convenor Permissions</a>
                                            
                                        </div>
                                        <div class="panel-content" v-if="user.convenor_permissions">
                                            <div class="padding" style="padding: 20px;">
                                                <b-field multiline grouped>
                                                    <b-switch v-model="user.convenor_permissions.create_tournament"
                                                        :true-value="1"
                                                        v-on:input="updateConvenor()"
                                                        :false-value="null">
                                                        Create Tournament
                                                    </b-switch>
                                                    <b-switch v-model="user.convenor_permissions.create_league"
                                                        :true-value="1"
                                                        v-on:input="updateConvenor()"
                                                        :false-value="null">
                                                        Create League
                                                    </b-switch>
                                                    <b-switch v-model="user.convenor_permissions.my_tournaments"
                                                        :true-value="1"
                                                        v-on:input="updateConvenor()"
                                                        :false-value="null">
                                                        My Tournaments
                                                    </b-switch>
                                                    <b-switch v-model="user.convenor_permissions.my_leagues"
                                                        :true-value="1"
                                                        v-on:input="updateConvenor()"
                                                        :false-value="null">
                                                        My Leagues
                                                    </b-switch>
                                                    <b-switch v-model="user.convenor_permissions.additional_tools"
                                                        :true-value="1"
                                                        v-on:input="updateConvenor()"
                                                        :false-value="null">
                                                        Additional Tools
                                                    </b-switch>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="panel-content" v-else>
                                            <div class="padding">
                                                This player has not hosted leagues or tournaments.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>
                </b-tabs>
              
            </div>
        </div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
import UmpCertificate from '../dashboard/umpires/components/UmpCertificate.vue'
export default {
	data() {

		return {
            sliceNum: 5,
            displayRegistrants: null,
            viewRegistrantsSwitch: false,
            toggleCallSwitch: false,
            full_written_address: null,
            basicDetailsEditSwitch: false,
            securityEditSwitch: false,
            emergencyEditSwitch: false,
            addressEditSwitch: false,
            authUser: { first_name: null },
            content_width: false,
            defaultOpenedDetails: [1],
            editUser: null,
            showDetailIcon: true,
            duplicateUser: null,
            user: null,
            note: null,
            notes: null,
            teams_theyre_on: null,
            tournaments_entered: null,
            isLoading: false,
            playersSelected: [],
            leagues_entered: null,
            searchList: [],
            en: true,
            // editor: ClassicEditor,
            perPage: 50,
            // editorData: '<p>Rich-text editor content.</p>',
            // editorConfig: {
            //     // The configuration of the rich-text editor.
            // },
            users: [],
            regions: [],
            location: null,
            google_primary_location: {
                region: null,
                written_address: null,
                street_address: null,
                city: null,
                province: null,
                latitude: null,
                longitude: null,
            },
            superadminModalSwitch: true,
            authPermissions: null,
            columnTemplate: [
                { title: 'ID', field: 'id', visible: false },
                { title: 'Region', field: 'default_region_id', visible: true, searchable: true },
                { title: 'Gender', field: 'gender', visible: true, searchable: true },
                { title: 'Rank', field: 'current_rank_id', visible: true, searchable: true, width: 50 },
                { title: 'First Name', field: 'first_name', visible: true, searchable: true },
                { title: 'Last Name', field: 'last_name', visible: true, searchable: true },
                { title: 'Username', field: 'username', visible: true, searchable: true },
                { title: 'Email', field: 'email', visible: true, searchable: true },
                { title: 'Phone', field: 'phone', visible: true, searchable: true },
                { title: 'City', field: 'city', visible: true, searchable: true },
                { title: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
                { title: 'Waiver', field: 'latest_waiver_signed', visible: true, searchable: true },
                { title: 'Last Login', field: 'last_login', visible: false },
            ]
		};
	},
	components: {
		Header,
		Footer,
        RankComponent,
        UmpCertificate
    },
    watch: {
        addressEditSwitch: function(result) {
            var vm = this
        
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        var id = this.$route.params.id

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
        
        var options = {}
 
         axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})
        
        
        axios.get('/api/superuser/grab-notes/'+ id).then(results => {
			vm.notes = results.data.notes
		})
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.authPermissions = results.data.user_permissions
                if(results.data.user_permissions.manage_players !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/grab-user/' + id).then(results => {
                    if(results.data.success === true) {
                        vm.user = results.data.user
                        vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                        if(vm.user.banned_until) {
                            vm.user.banned_until = new Date(vm.user.banned_until)
                        }
                        vm.user.teams_theyre_on = null
                        vm.duplicateUser = _.clone(results.data.user)
                        // let dateFromApi = new Date(vm.duplicateUser.date_of_birth); // Your date from API
                        // vm.duplicateUser.date_of_birth = dateFromApi.toLocaleDateString();
                        
                        vm.duplicateUser.date_of_birth = this.convertUTCDateToLocalDate(vm.duplicateUser.date_of_birth) 
                        // let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        // vm.duplicateUser.date_of_birth = moment.tz(vm.duplicateUser.date_of_birth, 'UTC').tz(timezone).format('YYYY-MM-DD');
                    }
                })

                axios.get('/api/superuser/grab-user-player-coach/' + id).then(results => {
                    if(results.data.success === true) {
                        vm.teams_theyre_on = results.data.teams_theyre_on
                        vm.tournaments_entered = results.data.tournaments_entered
                        vm.leagues_entered = results.data.leagues_entered

                        // vm.user = results.data.user
                        // vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                        // vm.duplicateUser = results.data.user
                    }
                })

                // axios.get('/api/superuser/pages').then(results => {
                //     if(results.data.success === true) {
                //         vm.pages = results.data.pages
                //     }
                // })
            }
        })
	},
	computed: {
	
	},
	methods: {
        convertUTCDateToLocalDate(date) {
            var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
            var offset = date.getTimezoneOffset() / 60;
            var hours = date.getHours();
            newDate.setHours(hours - offset);
            return newDate;
        },
        resetWaiver: function() {
            var r = confirm('Are you sure you want to do this?')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/reset-waiver', {
                    user: vm.user.id
                }).then(results => {
                    this.$buefy.snackbar.open({
                        message: 'Waiver is reset. They will need to sign it again when they log in.',
                        type: 'is-success' // is-danger
                    })
                })
            }
        },
        // formatter (date) {
        //     var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
        //     var offset = date.getTimezoneOffset() / 60;
        //     var hours = date.getHours();
        //     newDate.setHours(hours - offset);
        //     return newDate;
        // },
        updateCertNumber: function() {
            var vm = this

            axios.post('/api/superuser/update-cert-number', {
                user: vm.user.id,
                umpire_info: vm.user.umpire_permissions
            }).then(results => {
                this.$buefy.snackbar.open({
                    message: 'Updated.',
                    type: 'is-success' // is-danger
                })
            })
        },
        updateUser: function() {
            var vm = this
            axios.get('/api/superuser/grab-user/' + vm.user.id).then(results => {
                if(results.data.success === true) {
                    vm.user = results.data.user
                    vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                    if(vm.user.banned_until) {
                        vm.user.banned_until = new Date(vm.user.banned_until)
                    }
                    vm.user.teams_theyre_on = null
                    vm.user.tournaments_entered = null
                    vm.user.leagues_entered = null

                    vm.duplicateUser = results.data.user
                    vm.duplicateUser.date_of_birth = this.convertUTCDateToLocalDate(vm.duplicateUser.date_of_birth) 
                }
            })

            axios.get('/api/superuser/grab-user-player-coach/' + vm.user.id).then(results => {
                if(results.data.success === true) {
                    vm.teams_theyre_on = results.data.teams_theyre_on
                    vm.tournaments_entered = results.data.tournaments_entered
                    vm.leagues_entered = results.data.leagues_entered

                    // vm.user = results.data.user
                    // vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                    // vm.duplicateUser = results.data.user
                }
            })
        },
        fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.location = place.formatted_address
            
			// find components
			var address_components = place.address_components
			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				vm.google_primary_location.region = region_data
			} else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.google_primary_location.region = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.google_primary_location = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
            }
            
            vm.location = place.formatted_address

			// set province to region  -- newTournamentRegion - need to only send the shortcode and then match them here (some provinces will have to apply to multiple areas)
				// maritimes == NS, PEI, NB

				

			// var i = 0
			// var string = ''
			// for(i; i < place.address_components.length; i++) {
			// 	if(place.address_components[i].types[0] !== 'subpremise') {
			// 		string += place.address_components[i].long_name + ', '
			// 	}
			// }
			// this.newTournament.headquarter_location = string
			// console.log(string)
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        isString: function($event) {
            
        },
        findRegion: function(row) {
            var vm = this
            var region = _.find(vm.regions, function(region) {
                return region.id === row
            })
            if(region) {
                return region.name
            }

            return null
        },
        resetPassword: function(user) {
            

        },
        saveBasicDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'basic',
                user_id: vm.duplicateUser.id,
                first_name: vm.duplicateUser.first_name,
                default_region_id: vm.duplicateUser.default_region_id,
                middle_name: vm.duplicateUser.middle_name,
                last_name: vm.duplicateUser.last_name,
                email: vm.duplicateUser.email,
                gender: vm.duplicateUser.gender,
                identify_as: vm.duplicateUser.identify_as,
                phone: vm.duplicateUser.phone,
                username: vm.duplicateUser.username,
                date_of_birth: moment(vm.duplicateUser.date_of_birth),
                current_rank_id: vm.duplicateUser.current_rank_id,
                banned_until: vm.duplicateUser.banned_until,
                updated_account: vm.duplicateUser.updated_account
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Basic details updated, refresh to see all changes.',
                        type: 'is-success' // is-danger
                    })
                    vm.basicDetailsEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Basic details did not update',
                        type: 'is-danger' // is-danger
                    })
                }
            })
            
            
        },
        saveSecurityDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'security',
                user_id: vm.duplicateUser.id,
                security_question: vm.duplicateUser.security_question,
                security_answer: vm.duplicateUser.security_answer,
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Security details updated',
                        type: 'is-success' // is-danger
                    })
                    vm.securityEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Security details did not update',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        saveEmergencyDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'emergency',
                user_id: vm.duplicateUser.id,
                emergency_contact: vm.duplicateUser.emergency_contact,
                emergency_contact_phone: vm.duplicateUser.emergency_contact_phone,
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Emergency updated',
                        type: 'is-success' // is-danger
                    })
                    vm.emergencyEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Emergency did not update.',
                        type: 'is-danger' // is-danger
                    })
                }
            })

            
        },
        saveAddressDetails: function() {
            var vm = this
            axios.post('/api/superuser/update-user', {
                type: 'address',
                user_id: vm.duplicateUser.id,
                user: {
                    full_written_address: vm.user.full_written_address,
                    street_address: vm.user.street_address,
                    city: vm.user.city,
                    province: vm.user.province,
                    postal_code: vm.user.postal_code,
                },
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Address updated! Refresh for this to update on-screen.',
                        type: 'is-success' // is-danger
                    })
                    vm.addressEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Address did not save properly.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addNote: function() {
            var vm = this
            axios.post('/api/superuser/save-note', {
                user_id: vm.duplicateUser.id,
                note: vm.note
            }).then(results => {
                if(results.data.success === true) {
                    vm.notes = results.data.notes
                    vm.note = null

                    this.$buefy.snackbar.open({
                        message: 'Successfully added a note to their file.',
                        type: 'is-success' // is-danger
                    })

                } else {

                    this.$buefy.snackbar.open({
                        message: 'Failed to add a note to their file.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addSuperadminPermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-superadmin-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {

                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }

        },
        addUmpirePermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-umpire-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {

                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {

                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        addConvenorPermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-convenor-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {

                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {
                        
                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        updateSuperadmin: function($event) {
            var vm = this
            axios.post('/api/superuser/update-superadmin-permissions', {
                user_id: vm.duplicateUser.id,
                superadmin_permissions: vm.user.user_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated superadmin permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not superadmin permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        updateConvenor: function($event) {
            var vm = this
            axios.post('/api/superuser/update-convenor-permissions', {
                user_id: vm.duplicateUser.id,
                convenor_permissions: vm.user.convenor_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated convenor permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not convenor permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        updateUmpire: function($event) {
            var vm = this
            axios.post('/api/superuser/update-umpire-permissions', {
                user_id: vm.duplicateUser.id,
                umpire_permissions: vm.user.umpire_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated umpires permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not umpires permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        deleteThisUser: function() {
            var vm = this
            var r = confirm('Are you sure you want to delete this user?')

            if(r === true) {
                axios.post('/api/superuser/delete-user', {
                    user_id: vm.user.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.updateUser()
                        this.$buefy.snackbar.open({
                            message: 'Deleted successfully.',
                            type: 'is-success' // is-danger
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'Did not delete successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        undelete: function() {
            var vm = this
            axios.post('/api/superuser/undelete', {
                user_id: vm.user.id
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateUser()
                    this.$buefy.snackbar.open({
                        message: 'Undeleted successfully.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not undelete successfully.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        manuallyConfirmUser: function() {
            var vm = this

            axios.post('/api/superuser/manually-confirm-user', {
                user_id: vm.user.id
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateUser()

                    this.$buefy.snackbar.open({
                        message: 'Account has been confirmed.',
                        type: 'is-success'
                    })
                }
            })
        },
        addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
        addToClinic: function() {
            
            var vm = this

            axios.post('/api/superuser/umpires-add-to-clinic', {
                registrants: vm.playersSelected,
                clinic_id: vm.displayRegistrants.id
            }).then(results => {
                
                if(results.data.success === true) {
                    
                    vm.playersSelected = []
                    vm.viewRegistrantsSwitch = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added registrants to your clinic.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding registrants to your clinic did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
		updatePlayerSearch: function($event) {
            var vm = this

            vm.isLoading = true

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post(`/api/users/search/players`, {
                    search_name: $event,
					region: vm.displayRegistrants.region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.isLoading = false
                        vm.searchList = results.data.users
                    }
                })
            }, 500)
        },
        removeUmpirePermissions: function(user) {
            var vm = this

            var r = confirm("Are you sure you want to do this?")

            if(r === true) {
                axios.post('/api/superuser/remove-umpire-permissions', {
                    user_id: user.id
                }).then(results => {
                    this.updateUser()
                })
            }
        },
        resetPassword: function(user) {
            var r = confirm('Are you sure you want to reset their password? Remind them, they will be sent an email, which they must click the link and log in.')
            var vm = this
            if(r === true) {
                axios.post('/auth/reset-password', {
                    email: user.email
                }).then(results => {
                    if(results.data.success === true) {
                        vm.rememberEmail = true
                        this.$buefy.snackbar.open({
                            message: 'Password reset sent to ' + user.first_name + ' ' + user.last_name,
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                        this.updateUser()
                    }
                })
            }
        },
        resendConfirmAccount: function(user) {
            var vm = this


            axios.post('/auth/resend-confirm-account', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email resent to ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    this.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email failed ' + user.first_name + ' ' + user.last_name,
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        resetFailedAttempts: function(user) {
            var vm = this

            axios.post('/auth/reset-failed-attempts', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Failed attempts reset for ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    this.updateUser()
                }
            })
        },
        sendWaiverReminder: function(user) {
            var vm = this
            axios.post('/api/users/send-waiver-reminder', {
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Waiver reminder has been sent!',
                        type: 'is-success' // is-danger
                    })
                    this.updateUser()
                }
            })
        },
        confirmNewRank: function(user) {
            var vm = this
            var r = confirm('Are you sure you want to do this?')
            if(r === true) {
                axios.post('/api/superuser/confirm-new-rank', {
                    user: user.id
                }).then(results => {
                    if(results.data.success === true) {
                        user.previous_rank_id = null
                        this.$buefy.snackbar.open({
                            message: 'Rank has been updated',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
