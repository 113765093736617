<template>
    <span>
        <div class="new-cert">
            <div class="new-cert-text">
                
                <div v-if="umpire_permissions">
                    <p><b>Cert #: {{ umpire_permissions.cert_number }}</b></p>
                    <p style="margin-top: 20px;"><b v-if="en">This card is official confirmation that:</b>
                    <b v-if="en === false">Cette carte est une confirmation officielle que :</b></p>
                    <h2 style="margin-top: 10px;">{{ umpire_permissions.user.first_name }} {{ umpire_permissions.user.last_name }}</h2>
                    <p>
                        <b v-if="en">has successfully completed the {{ moment(umpire_permissions.valid_until).format('YYYY') }} Slo-Pitch National umpire testing program.</b>
                        <b v-if="en === false">A terminé avec succès l’examen national d’arbitres Slo-Pitch National {{ moment(umpire_permissions.valid_until).format('YYYY') }}.</b>
                    </p>
                    <h5 style="margin-top: 0px;">
                        <i v-if="en">Issued on: {{ moment(umpire_permissions.created_at).format('YYYY-MM-DD') }}</i>
                        <i v-if="en===false">Issue le: {{ moment(umpire_permissions.created_at).format('YYYY-MM-DD') }}</i>
                    </h5>
                    <p><b>Valid until: </b>{{ moment(umpire_permissions.valid_until).format('YYYY-MM-DD') }}</p>
                    
                    <p v-if="en">Insurance is extended to you for {{ moment(umpire_permissions.valid_until).format('YYYY')}}. Umpires are covered for Slo-Pitch National sanctioned and approved games, tournaments and leagues while wearing an SPN Uniform.</p>
                    <p v-if="en===false">L’assurance vous est fournie pour 2021. Les arbitres sont couverts pour les tournois et ligues sanctionnés et approuvés par Slo-Pitch National tout en portant un uniforme du SPN.</p>
                </div>
            </div>
            <div class="new-cert-image">
                <img :src="umpireCertImage" alt="Umpire Cert">
                
            </div>
        </div>

        
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
	data() {
		return {
            umpire_permissions: null,
            
		};
	},
	computed: {
		umpireCertImage: function() {
            if(this.en === true) {
                return 'https://cdn.blacktiecollab.com/slo-pitch.com/English - Cert.png'
            }
            
                return 'https://cdn.blacktiecollab.com/slo-pitch.com/French - Cert.png'
            
        }
	},
	props: ['en','userId'],
	mounted: function() {
        var vm = this 

        if(vm.userId) {
            axios.get('/api/umpires/umpire-lookup/' + vm.userId).then(results => {  
                if(results.data.success === true) {
                    vm.umpire_permissions = results.data.umpire_permissions
                }
            })
        }
    },
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		}
	}
}
</script>

<style lang="scss">
    .new-cert {
        position: relative;
        width: 100%;
        height: 100%;

        .new-cert-text {
            position: absolute;
            top: 30%;
            left: 10%;
            right: 10%;
            z-index: 1000;
            text-align: center;
        }
        p {
            font-size: 14px;
        }
        h5 {
            font-size: 18px;
            margin-top: 30px;
            font-weight: 700;
            text-transform: uppercase;
            color: #cc0000;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 30px;
            margin-top: 40px;
            margin-bottom: 15px;
            color: #444;
            border-bottom: 2px solid #cc0000;
            font-family: 'Oswald', sans-serif;
            
            text-transform: uppercase;
            
            @media all and (max-width: 700px) {
                font-size: 20px;
            }
        }
    }
</style>
